import React from "react";
import { Box, Flex, Text } from "theme-ui";
import Link from "components/Link";

const Footer = ({ sx, ...props }) => (
  <Box
    sx={{
      bg: "grey.200",
      color: "text.disabled",
      py: [6, 10],
      ...sx,
    }}
    {...props}
  >
    <Box
      sx={{
        maxWidth: "maxContent",
        p: [4, 10],
        mx: "auto",
      }}
    >
      <Flex
        sx={{
          flexDirection: ["column", "row"],
          flexWrap: ["nowrap", "wrap", "nowrap"],
          alignItems: "start",
          justifyContent: ["center", "start"],
          mt: 10,
        }}
      >
        <Link
          to="/privacy"
          sx={{
            alignSelf: "start",
            mb: [4, 0],
            mr: 6,
            whiteSpace: ["initial", "nowrap"],
          }}
        >
          Privacy Policy
        </Link>
        <Link
          to="/terms"
          sx={{ alignSelf: "start", whiteSpace: ["initial", "nowrap"] }}
        >
          Terms and Conditions
        </Link>
      </Flex>
    </Box>
  </Box>
);

export default Footer;
